// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { createElement, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { keyCode, redirect } from 'jsw';
import { useConfigContext } from 'plesk/components/ConfigContext';
import Link from 'plesk/components/Link';
import Translate from 'plesk/components/Translate';

const MainHeaderSearch = ({ url, headerRef }, { locale }) => {
    const searchActionDelay = 200;
    const containerRef = useRef();
    const searchTermRef = useRef();
    const iconStateRef = useRef();
    const timer = useRef(null);
    const enterPressed = useRef(false);
    const pendingRequest = useRef(null);
    const skipKeyUp = useRef(false);
    const touchMoved = useRef(false);
    const config = useConfigContext();

    const [isSearchFocused, setSearchFocused] = useState(false);
    const [results, setResults] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const fatalError = message => {
        // eslint-disable-next-line no-alert
        alert(message);
    };

    const handlePaste = () => scheduleSearch();

    const handleKeyUp = event => {
        if (skipKeyUp.current) {
            return;
        }
        if (event.keyCode === keyCode.ESC) {
            resetSearch({ resetValue: false });
            return;
        }
        if ([keyCode.UP_ARROW, keyCode.DOWN_ARROW, keyCode.LEFT_ARROW, keyCode.RIGHT_ARROW, keyCode.ENTER].indexOf(event.keyCode) !== -1) {
            return;
        }

        scheduleSearch();
    };

    const handleKeyDown = event => {
        skipKeyUp.current = event.ctrlKey || event.metaKey;

        if ([keyCode.UP_ARROW, keyCode.DOWN_ARROW].indexOf(event.keyCode) !== -1) {
            onArrowKeyPressed(event.keyCode);
            event.preventDefault();
        }

        enterPressed.current = (keyCode.ENTER === event.keyCode);
        if (enterPressed.current) {
            scheduleSearch();
        }
    };

    const handleFocus = () => {
        if (resetTimeout) {
            clearTimeout(resetTimeout);
        }

        resetSearch();
        setSearchFocused(true);

        document.addEventListener('touchstart', onDocumentTouchStart);
        document.addEventListener('touchmove', onDocumentTouchMove);
        document.addEventListener('touchend', onDocumentTouchEnd);
    };

    let resetTimeout = null;
    const handleBlur = () => {
        if (resetTimeout) {
            clearTimeout(resetTimeout);
        }

        resetTimeout = setTimeout(() => {
            resetSearch();
            setSearchFocused(false);
        }, 300);
    };

    const scheduleSearch = () => {
        if (searchTermRef.current.value) {
            iconStateRef.current.classList.add('icon-indicator');
            iconStateRef.current.classList.remove('icon-search');
        }

        abortPreviousSearch();
        timer.current = setTimeout(findTerm, searchActionDelay);
    };

    const abortPreviousSearch = () => {
        if (pendingRequest.current) {
            const abort = pendingRequest.current.abort.bind(pendingRequest.current);
            // global state is cleared first due to onSearchComplete callback
            pendingRequest.current = null;
            abort();
        }

        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    };

    const onSearchSuccess = response => {
        if (searchTermRef.current.value !== response.request.options.parameters.term) {
            return;
        }

        let data;
        try {
            data = JSON.parse(response.responseText);
        } catch (e) {
            fatalError(`Failed to parse JSON response: ${e.message}`);
            return;
        }

        if ('error' === data.status) {
            const result = data.statusMessages.reduce((res, message) => `${res}${message.title}: ${message.content}\n`, '');
            fatalError(result);
            return;
        }

        setResults(data);
        if (enterPressed.current) {
            const item = data.records[selectedItem || 0];
            if (item) {
                redirect(item.link, null, item.target);
            }
        } else if (data.records.length > 0) {
            setSelectedItem(0);
        }
    };

    const onSearchFailure = response => {
        fatalError(`Search request failed due to following error: ${response.responseText}`);
    };

    const onSearchComplete = response => {
        if (!response || pendingRequest.current === response.request) {
            iconStateRef.current.classList.remove('icon-indicator');
            iconStateRef.current.classList.add('icon-search');
        }
    };

    const renderResults = () => {
        if (!results) {
            return null;
        }

        return (
            <ul
                id="searchResultsBlock"
                className="dropdown-menu dropdown-icon-menu"
            >
                {results.records.length ? results.records.map(({ details, target, icon, link, title }, index) => {
                    const iconUrl = (
                        !icon ||
                        icon.startsWith(Jsw.skinUrl) ||
                        icon.startsWith('http://') ||
                        icon.startsWith('https://') ||
                        icon.startsWith('/modules/')
                    ) ? icon : `${Jsw.skinUrl}${icon}`;

                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className={selectedItem === index && 'active'}>
                            <Link
                                to={link}
                                title={details}
                                target={target}
                                onMouseOver={() => setSelectedItem(index)}
                                onMouseOut={() => setSelectedItem(null)}
                            >
                                {iconUrl ? (
                                    <i className="icon-" style={{ backgroundImage: `url(${iconUrl})` }} />
                                ) : null}
                                {title}
                            </Link>
                        </li>
                    );
                }) : (
                    <li>
                        <div className="search-results-note">
                            <Translate content="search-bar.nothingFound" />
                        </div>
                    </li>
                )}
                {results.meta.moreResultsFound ? (
                    <li>
                        <div className="search-results-note">
                            <Translate content="search-bar.moreResultsFound" params={{ limit: config.search.limit }} />
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    const resetSearch = ({ resetValue = true } = {}) => {
        if (resetValue) {
            searchTermRef.current.value = '';
        }

        setSelectedItem(null);
        setResults(null);

        abortPreviousSearch();
        onSearchComplete();
    };

    const onArrowKeyPressed = key => {
        if (!results || !results.records.length) {
            return;
        }

        if (selectedItem === null) {
            setSelectedItem(0);
            return;
        }

        if (keyCode.DOWN_ARROW === key && selectedItem < results.records.length - 1) {
            setSelectedItem(selectedItem + 1);
        }

        if (keyCode.UP_ARROW === key && selectedItem > 0) {
            setSelectedItem(selectedItem - 1);
        }
    };

    const findTerm = () => {
        const term = searchTermRef.current.value;

        if ('' === term) {
            resetSearch();
            return;
        }

        pendingRequest.current = new Ajax.Request(
            url,
            {
                method: 'get',
                parameters: { term },
                onSuccess: onSearchSuccess,
                onFailure: onSearchFailure,
                onComplete: onSearchComplete,
            }
        );
    };

    const onDocumentTouchStart = useCallback(() => {
        touchMoved.current = false;
    }, [touchMoved]);
    const onDocumentTouchMove = useCallback(() => {
        touchMoved.current = true;
    }, [touchMoved]);
    const onDocumentTouchEnd = useCallback(() => {
        if (touchMoved.current) {
            return;
        }

        searchTermRef.current.blur();

        document.removeEventListener('touchstart', onDocumentTouchStart);
        document.removeEventListener('touchmove', onDocumentTouchMove);
        document.removeEventListener('touchend', onDocumentTouchEnd);
    }, [touchMoved, searchTermRef, onDocumentTouchStart, onDocumentTouchMove]);

    return (
        <div
            ref={containerRef}
            className="main-header-search"
            onClick={() => {
                searchTermRef.current.focus();
            }}
            onMouseEnter={() => {
                headerRef.current.classList.add('page-header-search-hover');
            }}
            onMouseLeave={() => {
                headerRef.current.classList.remove('page-header-search-hover');
            }}
            onTouchEnd={e => {
                e.stopPropagation();
            }}
        >
            <div className={classNames('form-group', 'dropdown', isSearchFocused && 'search-focused', results && 'open')}>
                <input
                    ref={searchTermRef}
                    id="searchTerm"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    placeholder={locale.lmsg('search-bar.fieldStub')}
                    onPaste={handlePaste}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <span className="form-control-feedback icon-search" ref={iconStateRef} />
                <span
                    className="form-control-feedback icon-close"
                    tabIndex="0"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
                {renderResults()}
            </div>
        </div>
    );
};


MainHeaderSearch.propTypes = {
    url: PropTypes.string.isRequired,
    headerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

MainHeaderSearch.contextTypes = {
    locale: PropTypes.object,
};

export default MainHeaderSearch;
