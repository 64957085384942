// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useModal = path => {
    const key = `modals[${path}]`;
    const history = useHistory();
    const location = useLocation();

    const [value, setValue] = useState(() => new URLSearchParams(location.search).get(key));

    useEffect(() => {
        const { pathname, search } = location;
        const params = new URLSearchParams(search);

        if (value === null) {
            if (params.has(key)) {
                params.delete(key);
                history.push(`${pathname}?${params}`);
            }
            return;
        }

        if (String(value) !== params.get(key)) {
            params.set(key, value);
            history.push(`${pathname}?${params}`);
        }
    }, [key, value, history, location]);

    return [value, setValue];
};

export default useModal;
