// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { createElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate as BaseTranslate } from '@plesk/ui-library';

const Translate = ({ component, ...props }) => (
    <BaseTranslate component={component} {...props} />
);

Translate.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
};

Translate.defaultProps = {
    component: Fragment,
};

export default Translate;
